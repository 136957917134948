$lightGray: #cecece;
$grayDark: #a2a5a4;
$gray2: #183956;
$gray5: #f0f5f8;
$gray6: #667d99;
$gray7: #050615;
$red: #b10000;
$lightRed: #db4835;
$orange: #c5782b;
$lightOrange: #f6e6d5;
$hoveredLink: #1c5a84;
$white: #ffffff;
$black: #21282a;
$black1: #212121;
$darkBlueGrey: #333f4d;
$grey: #7a7d80;
$lightBlueGrey: #acb9c8;
$link: #2979ff;
$green: #268e36;
$green2: #296932;
$lightBrightGreen: #90ee90;
$lightGreen: #9ccba3;
$pink: #e91e63;
$yellow: #f2ef1d;
$whatsappGreen: #128c7e;
$lightOrangeDark: #db9753;

$blackTransparent: rgba(0, 0, 0, 0.870588);
$transparent: rgba(0, 0, 0, 0);

$error: $red;

$themeLightGreen: #5eaa3d;
$themeGreen: #274a12;
$themeLightGrey: #8c8c8c;
$themeGrey: #36474f;
$themeDarkGrey: #1b282e;

$themePrimaryLight: $themeLightGreen;
$themePrimaryMain: $themeLightGreen;
$themePrimaryDark: $themeGreen;

$themeSecondaryLight: $lightOrange;
$themeSecondaryMain: $lightOrangeDark;
$themeSecondaryDark: $orange;



$opesi: $hoveredLink;
$opesi2: $orange;

$xxSmallScreenWidth: 375px;
$xSmallScreenWidth: 479px;
$smallScreenWidth: 768px;
$mediumScreenWidth: 968px;
$xMediumScreenWidth: 1024px;
$newLargeScreenWidth: 1366px;
$largeScreenWidth: 1440px;
$drawerWidth: 400px;

$breakpoints: (
  xxSmallScreenBkp: $xxSmallScreenWidth,
  xSmallScreenBkp: $xSmallScreenWidth,
  smallScreenBkp: $smallScreenWidth,
  mediumScreenBkp: $mediumScreenWidth,
  xMediumScreenBkp: $xMediumScreenWidth,
  newLargeScreenBkp: $newLargeScreenWidth,
  largeScreenBkp: $largeScreenWidth,
  tillLargeScreenBkp: 1300px,
  drawerWidth: $drawerWidth
);

// This mixin will handle larger than breakpoints
// @include media-breakpoint-up(smallMobileBreakpoint) {}
// mq-up > media breakpoint up
@mixin mq-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (min-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle smaller than breakpoints
// @include media-breakpoint-down(bigScreenBreakpoint) {}
// mq-down > media breakpoint down
@mixin mq-down($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: $breakpoint-value) {
      @content;
    }
  } @else {
    // Use custom value passed
    @media (max-width: $breakpoint) {
      @content;
    }
  }
}

// This mixin will handle sizez between breakpoints you choose
// @include media-breakpoint-between(mobileBreakpoint, smallScreenWidth) {}
// mq-between > media breakpoint between
@mixin mq-between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @if ($lower-breakpoint > $upper-breakpoint) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower} > #{$upper}!";
    }

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint)) {
      @content;
    }
  } @else {
    // If custom values
    @if ($lower > $upper) {
      // Send error if first parameter size is bigger then the second
      @error "First parameter should represent lower value and it's size should be smaller. In your case #{$lower}px > #{$upper}px!";
    }

    @media (min-width: $lower) and (max-width: ($upper)) {
      @content;
    }
  }
}

@mixin mq-dark-mode {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

@mixin hide-on-print {
  @media print {
    display: none;
  }
}

@mixin show-on-print {
  display: none;
  @media print {
    display: block;
  }
}

.notifRow {
  padding-left: 8px;
  cursor: pointer;
  @include mq-down(xSmallScreenBkp) {
    padding-left: 6px;
  }
  border-left: 4px solid transparent;

  &:hover {
    border-left: 4px solid $green;
    background-color: rgba($green, 0.2);
  }
}

.productDescription {
  font-size: 16px;
  max-width: 250px;
  font-weight: bold;
  @include mq-down(xSmallScreenBkp) {
    font-size: 11px;
  }
}

.productDescription.fullWidth {
  max-width: unset;
}

.extraInfo {
  font-size: 14px;
  font-weight: 600;
  color: $grey;
  flex: 2;
}

.total {
  font-size: 16px;
  font-weight: bold;
  color: $darkBlueGrey;
  flex: 1;
}

.iconRoot {
  min-width: 30px;
  @include mq-down(xxSmallScreenBkp) {
    min-width: 16px;
  }
}

.listItem {
  padding-left: 8px;
  padding-right: 8px;
  @include mq-down(xxSmallScreenBkp) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.clearIcon {
  font-size: 20px;
  width: 20px;
  @include mq-down(xxSmallScreenBkp) {
    font-size: 16px;
    width: 16px;
  }
}

.productImage {
  width: 80px !important;
  height: 80px !important;
}

.divider {
  max-width: 85%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.field {
  max-width: 100px;

  input {
    height: 0px;
  }
}
